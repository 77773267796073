<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Supplier</strong> </CCardHeader>
          <CForm autocomplete="off">
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="First Name"
                    v-model="supplier_to_edit.first_name"
                    placeholder="Enter first name..."
                    required
                    @keyup="check_first_name"
                    :addInputClasses="first_name_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.first_name ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Last Name"
                    v-model="supplier_to_edit.last_name"
                    placeholder="Enter last name..."
                    required
                    @keyup="check_last_name"
                    :addInputClasses="last_name_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.last_name ? true : null"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CTextarea
                    label="Address"
                    v-model="supplier_to_edit.address"
                    placeholder="Enter Address..."
                    required
                    @keyup="check_address"
                    :addInputClasses="address_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.address ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Company"
                    v-model="supplier_to_edit.company_name"
                    placeholder="Enter company name..."
                    required
                    @keyup="check_company"
                    :addInputClasses="company_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.company_name ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Contact No"
                    v-model="supplier_to_edit.contact_no"
                    placeholder="Enter contact number..."
                    required
                    @keyup="check_contact_no"
                    :addInputClasses="contact_no_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.contact_no ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="email"
                    autocomplete="off"
                    label="Email"
                    v-model="supplier_to_edit.email"
                    placeholder="Enter Email..."
                    required
                    @keyup="check_email"
                    :addInputClasses="email_invalid ? 'is-invalid' : null"
                    :isValid="supplier_to_edit.email ? true : null"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SupplierEdit",

  data() {
    return {
      email: "",
      supplier_to_edit: [],
      email_invalid: false,
      last_name_invalid: false,
      first_name_invalid: false,
      address_invalid: false,
      company_invalid: false,
      contact_no_invalid: false,
    };
  },
  computed: {
    ...mapState({
      editSupplier: (state) => state.suppliers.editSupplier,
      isSaving: (state) => state.suppliers.isSaving,
    }),
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editSupplier) {
      this.$router.push({ name: "Suppliers" });
    }
    this.beforeEditingCache = JSON.stringify(this.editSupplier);
    this.supplier_to_edit = this.editSupplier
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
  },

  methods: {
    check_first_name() {
      if (!this.supplier_to_edit.first_name) {
        this.first_name_invalid = true;
      } else {
        this.first_name_invalid = false;
      }
    },
    check_last_name() {
      if (!this.supplier_to_edit.last_name) {
        this.last_name_invalid = true;
      } else {
        this.last_name_invalid = false;
      }
    },
    check_company() {
      if (!this.supplier_to_edit.company_name) {
        this.company_invalid = true;
      } else {
        this.company_invalid = false;
      }
    },
    check_address() {
      if (!this.supplier_to_edit.address) {
        this.address_invalid = true;
      } else {
        this.address_invalid = false;
      }
    },
    check_contact_no() {
      if (!this.supplier_to_edit.contact_no) {
        this.contact_no_invalid = true;
      } else {
        this.contact_no_invalid = false;
      }
    },
    check_email() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.supplier_to_edit.email).toLowerCase())) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    checkForm() {
      if (
        !this.supplier_to_edit.email ||
        !this.supplier_to_edit.last_name ||
        !this.supplier_to_edit.first_name ||
        !this.supplier_to_edit.contact_no ||
        !this.supplier_to_edit.address ||
        !this.supplier_to_edit.company_name
      ) {
        this.check_last_name();
        this.check_first_name();
        this.check_company();
        this.check_contact_no();
        this.check_email();
        this.check_address();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("suppliers/updateSupplier", {
            Authorization: "bearer " + this.$store.state.access_token,
            id: this.supplier_to_edit.id,
            email: this.supplier_to_edit.email,
            address: this.supplier_to_edit.address,
            contact_no: this.supplier_to_edit.contact_no,
            last_name: this.supplier_to_edit.last_name,
            first_name: this.supplier_to_edit.first_name,
            company_name: this.supplier_to_edit.company_name,
          })
          .then((response) => {
            this.$router.push({ name: "Suppliers" });
          });
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 